export interface IApiParams {
    url: string;
    method?: string;
    headers?: Headers;
    body?: string;
    credentials?: boolean;
}

export async function CallApi<T>(params: IApiParams): Promise<T> {

    const options = {} as RequestInit;

    options.method = params.method ?? "GET";
    options.body = params.body ?? undefined;
    options.headers = params.headers ?? undefined;
    options.credentials = params.credentials ? "include" : undefined;

    let response = await fetch(params.url, options);
    return await response.json() as Promise<T>;
}
