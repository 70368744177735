import {bindHandlers} from "./tools";

class ExportWatcher {

    private socketAddress: string = '';
    private socket: WebSocket | null = null;
    private pingInterval: number = 100;

    constructor() {
        bindHandlers(this);

    }


    start(socketAddress: string, pingInterval: number = 100) {
        console.log(`Initializing ExportWatcher with socket address: ${socketAddress}`);
        this.socketAddress = socketAddress;
        this.pingInterval = pingInterval;
        this.connect();
    }

    stop() {
        if (this.socket) {
            this.socket.close();
        }
    }

    private connect() {
        this.socket = new WebSocket(this.socketAddress);
        this.socket.onopen = this.onWebsocketOpenHandler;
        this.socket.onerror = this.onWebsocketErrorHandler;
        this.socket.onclose = this.onWebsocketCloseHandler;
        this.socket.onmessage = this.onWebsocketMessageHandler;
    }

    onWebsocketOpenHandler(event: Event) {
        console.log('connected ' + event);

        let id = setInterval(() => {
            if (this.socket!.readyState === WebSocket.OPEN) {
                this.socket!.send('ping');
            } else {
                console.log('websocket disconnected, refresh browser when ready')
                clearInterval(id);
            }

        }, this.pingInterval);
    }

    onWebsocketErrorHandler(event: Event) {
        console.log('error' + event);
    }

    onWebsocketMessageHandler(event: MessageEvent) {
        console.log('message' + event.data);
        this.socket!.close();
        window.location.reload();
    }

    onWebsocketCloseHandler(event: CloseEvent) {
        console.log('closed' + event);
        console.log('reconnecting');
        this.connect();
    }
}


export const refreshWatcher = new ExportWatcher();