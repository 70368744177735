import {CallApi} from "./support/call_api";
import {JSendResponse} from "wielding-events/lib/JSendResponse";
import {ConfigReceivedEvent, IConfigModel} from "./stores/config_events";
import {appStore} from "./stores/app_store";
import {State} from "./support/state";
import {refreshWatcher} from "./support/RefreshWatcher";

let appConfig = {} as IConfigModel;

document.addEventListener('DOMContentLoaded', async () => {
    console.log('DOM loaded');

    await CallApi<JSendResponse<IConfigModel>>({url: "/api/v1/config", method: 'GET'})
        .then((response) => {
            appConfig = response.data;
            appStore.Dispatch(new ConfigReceivedEvent(response.data), true, true);
            State.Instance.ApiUrl = response.data.api_url;
        })
        .catch((error) => {
            console.log(error);
        });

    let configInfo = document.getElementById('config-info');

    configInfo!.innerText = `pi: ${appConfig.ping_interval} pc: ${appConfig.ping_count}`;


    if (document.location.port === "9101") {
        refreshWatcher.start("ws://localhost:8090/watch");
    }
});



